/* eslint-disable */
<template>
  <div class="card social-card-h">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">
            {{ $t("social_traffic_table.test_generation_highlights") }}
          </h3>
        </div>
      </div>
    </div>
    <div class="table-responsive" v-if="tghData.length > 0">
      <el-table
        :emptyText="$t('social_traffic_table.empty')"
        class="table-responsive table"
        header-row-class-name="thead-primary"
        :data="formatRow()"
        height="800"
        @row-click="onRowClicked"
      >
        <el-table-column
          :label="$t('social_traffic_table.test_name')"
          min-width="10"
          prop="name"
        >
          <template v-slot="{ row }">
            <div class="font-weight-600 elipsis--text" v-if="row">
              {{ row.name }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          class="text-center"
          label="#Tests"
          min-width="5"
          prop="step_count"
        >
          <template v-slot="{ row }">
            <div class="font-weight-600 elipsis--text" v-if="row">
              {{ row.step_count }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
<!--    <div v-else>-->
<!--      <img-->
<!--        src="/img/loading.gif"-->
<!--        alt="loading..."-->
<!--        style="height: 100px; width: 100px"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
export default {
  name: "social-traffic-table",
  props: {
    tghData: Array,
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      log: console.log,
      toShow: [],
    };
  },
  created() {
    this.toShow = [...this.tghData].filter((elt, id) => id < 7);
  },
  methods: {
    formatRow() {
      let a = this.tghData;
      let b = [];
      for (var i = 0; i < a.length; i++) {
        b.push(a[i]);
      }
      return b;
    },
    onRowClicked(item, index, event) {
      const element = event.srcElement.id;
      if (element === "") {
        if (item.testSuiteId) {
          this.$router.push({
            name: "Suite View Item",
            params: {
              suiteId: item.testSuiteId,
              id: item.requestId,
            },
          });
        } else {
          this.$router.push({
            name: "View Item",
            params: {
              id: item.request_id,
            },
          });
        }
      }
    },
  },
};
</script>
<style>
.social-card-h {
  height: 500px;
}

.social-card-h .table-responsive {
  overflow: hidden !important;
}

.table-responsive .el-table__row {
  background: orange;
}

.table-responsive .el-table__body-wrapper .el-table__body .el-table_1_column_1 {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

.table-responsive
  .el-table__body-wrapper
  .el-table__body
  .el-table_1_column_1
  .cell {
  line-height: 1.5;
  padding: 0.5rem !important;
  margin: 0 !important;
}

.elipsis--text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
