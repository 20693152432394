<template>
  <div class="temp flex">
    <left-side-bar v-if="toShowLatestProjects.length > 0">
      <div class="eff--page px-3">
        <!-- <div class="ctb--body mb-3" v-if="loadingProject">
          <div class="ctbb--row mb-2">
            <img src="/img/loading.gif" alt="loading..." style="height: 100px; width: 100px" />
          </div>
        </div> -->
        <div class="ctb--body mb-3 mt-3">
          <h2>Last Activity</h2>
          <template v-if="toShowLatestProjects.length > 0">
                <div v-for="elt in toShowLatestProjects" :key="elt.id" class="ctbb--row mb-2" @click="showComponent(elt.projectId)">
                  <div class="ctbb--inner">
                    <div class="elt--banner" >
                      <div  class="elt--banner_img elt--banner_name" >
                        {{ getSlicName(elt.name) }}
                      </div>
                      <div class="elt--banner_overlay"></div>
                      <el-alert v-if="elt.isSharedProject && elt.isSharedProject === true" class="no--close elt--banner_overlay_status" show-icon title="Shared with you" type="success"></el-alert>
                      <el-tooltip v-if="elt.creatorEmail" class="item" effect="light" content="Creator" placement="top">
                        <span v-if="elt.creatorEmail && elt.isSharedProject && elt.isSharedProject === true" class="elt--banner_overlay_created">
                          <i class="far fa-user mr-1"></i>
                          {{ elt.creatorEmail }}
                        </span>
                      </el-tooltip>
                    </div>
                    <div class="elt--content" >
                      <span class="test-run-name"> {{ elt.name }} </span>
                      <el-divider></el-divider>

                    </div>
                  </div>
                </div>
          </template>
        </div>
      </div>
    </left-side-bar>
    <div class="container-content right-content">
      <base-header class="mb-3 pt-4 bg-gradient-info" style="background-color: #5E72E4 !important;">
        <div class="row">
          <div class="col-xl-3 col-md-6 ">
            <stats-card v-loading="loadingSession" type="gradient-indigo" :title="$t('dashboard.events_gen')" :sub-title="totalSession.toString()
              " icon="fas fa-clipboard">
              <!--                 tests_by_app: "Test Runs By App",-->
              <!--    events_last_week: "New Events Generated Last Week",-->
              <!--    key_flows_last_week: "Key Flows Generated",-->
              <!--    bugs_discovered: "Bugs Discovered",-->
              <!--    bug_finder_runs: "Bug Finder Runs",-->
              <!--    latest_session_video: "Latest Session Video",-->
              <!--    latest_key_flow: "Latest Key Flow",-->

              <template slot="footer">
                <p class="mt-3 mb-0 text-sm">
                  <!--                <span class="text-success mr-2"-->
                  <!--                  ><i class="fa fa-arrow-up"></i> 3.48%</span-->
                  <!--                >-->
                  <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                </p>
              </template>
            </stats-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <stats-card v-loading="loadingAppMap" :title="$t('dashboard.key_flows_gen')" type="gradient-blue" :sub-title="toShowKeyFlows.toString()
              " icon="fas fa-clipboard-list">
              <template slot="footer">
                <p class="mt-3 mb-0 text-sm">
                  <!--                <span class="text-success mr-2"-->
                  <!--                  ><i class="fa fa-arrow-up"></i> 12.18%</span-->
                  <!--                >-->
                  <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                </p>
              </template>
            </stats-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <stats-card v-loading="loading" :title="$t('dashboard.bugs_discovered')" type="gradient-teal" :sub-title="dashboardData.num_healed ? Math.floor(dashboardData.num_healed / 2).toString() : '0'
              " icon="fas fa-notes-medical">
              <template slot="footer">
                <p class="mt-3 mb-0 text-sm">
                  <!--                <span class="text-danger mr-2"-->
                  <!--                  ><i class="fa fa-arrow-down"></i> 5.72%</span-->
                  <!--                >-->
                  <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                </p>
              </template>
            </stats-card>
          </div>
          <div class="col-xl-3 col-md-6">
            <stats-card v-loading="loading" :title="$t('dashboard.bug_finder_runs')" type="gradient-green" :sub-title="bugFinder ? bugFinder.toString() : '0'
              " icon="far fa-clock">
              <template slot="footer">
                <p class="mt-3 mb-0 text-sm">
                  <!--                <span class="text-success mr-2"-->
                  <!--                  ><i class="fa fa-arrow-up"></i> 54.8%</span-->
                  <!--                >-->
                  <span class="text-nowrap">{{ $t('dashboard.since_last_week') }}</span>
                </p>
              </template>
            </stats-card>
          </div>
        </div>
      </base-header>

      <!--Charts-->
      <div class="container-fluid bg-gradient-secondary">
        <div class="row">
          <div class="col-xl-8">
            <div class="bg-white rounded">
              <!--            <v-chart-->
              <!--                title="$t('dashboard.average_runtime')"-->

              <!--                class="chart"-->
              <!--              :autoresize="true"-->
              <!--              :loading="chartLoading"-->
              <!--              :option="dashboardLineChart"-->
              <!--              v-loading="loading"-->
              <!--            />-->
              <div class="">
                <div class="gen-heigh">
                  <social-traffic-table :tghData="testGenerationTableData"></social-traffic-table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 r-3-cards">
            <div style="cursor: pointer;" @click="showDetailsSession(resultSession[0])">
              <stats-card v-loading="loadingAppMap" class="only-body" :title="$t('dashboard.latest_session_video')"
                type="gradient-red" :sub-title="resultSession[0]
                  ? resultSession[0]?.person.properties.$geoip_city_name + `, ` + resultSession[0]?.person.properties.$geoip_country_code + ` on ` + formatDate(resultSession[0]?.start_time) + ` for ` + secondsToRealTime(secondsToRealTimeEvent(resultSession[0]?.start_time, resultSession[0]?.end_time))
                  : '0'
                  " icon="fas fa-stopwatch">
              </stats-card>
            </div>
            <stats-card v-loading="loadingAppMap" class="only-body" :title="$t('dashboard.latest_key_flow')"
              type="gradient-orange" :sub-title="toShowLatestKeyFlow
                ? `Afchess:` + ` ` + toShowAllKeyFlows[toShowLatestKeyFlow]?.label.toString().toUpperCase().replace(/[\.]/g, '')
                : '0'
                " icon="fas fa-hourglass-end">
            </stats-card>
            <!--          <stats-card-->
            <!--            v-loading="loading"-->
            <!--            class="only-body"-->
            <!--            :title="$t('dashboard.test_case_healed')"-->
            <!--            type="gradient-red"-->
            <!--            :sub-title="-->
            <!--              +parseFloat(dashboardData.test_improvements * 100).toFixed(2) +-->
            <!--                '%'-->
            <!--            "-->
            <!--            icon="fas fa-chart-pie"-->
            <!--          >-->
            <!--          </stats-card>-->
          </div>
        </div>
        <!-- End charts-->

        <!--Tables-->
        <div class="row">
          <!--        <div class="col-xl-6">-->
          <!--          <div class="gen-heigh">-->
          <!--            <social-traffic-table-->
          <!--              :tghData="testGenerationTableData"-->
          <!--            ></social-traffic-table>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <div class="col-xl-6 h-50">-->
          <!--          <card header-classes="bg-transparent" class="test-card-h">-->
          <!--            <div slot="header" class="row align-items-center">-->
          <!--              <div class="col">-->
          <!--                <h5 class="h3 mb-0">{{ $t('dashboard.test_heals') }}</h5>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <v-chart-->
          <!--              class="chart"-->
          <!--              :autoresize="true"-->
          <!--              :option="dashboardPieChart"-->
          <!--              v-loading="loading"-->
          <!--            />-->
          <!--          </card>-->
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import StatsCard from "@/components/Cards/StatsCard";
import SocialTrafficTable from "./SocialTrafficTable";
import "echarts";
import LeftSideBar from "../../components/SidebarPlugin/LeftSideBar.vue"
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import axios from "axios";
import moment from "moment";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    StatsCard,
    SocialTrafficTable,
    LeftSideBar
  },
  data() {
    return {
      log: console.log,
      chartLoading: false,
      testGenerationTableData: [],
      bg: "",
      dashboardData: "",
      dashboardLineChart: {
        title: {
          text: "",
          left: "center",
          top: "center",
        },
        tooltip: {
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          data: [],
        },
        yAxis: {},
        series: [
          {
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            type: "line",
            smooth: true,
            areaStyle: {
              color: "#4ac3db",
              opacity: 0.5,
            },
          },
          {
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            type: "line",
            smooth: true,
            areaStyle: {
              color: "#FCA311",
              opacity: 0.5,
            },
          },
        ],
      },
      dashboardPieChart: {
        legend: {
          orient: "vertical",
          x: "left",
          data: [],
        },
        title: {
          text: "Test Heals",
          left: "center",
          top: "center",
        },
        series: [
          {
            type: "pie",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "#000000",
              },
            },
            radius: ["40%", "70%"],
          },
        ],
      },
      loading: false,
      resultSession: {},
      totalSession: 0,
      loadingProject:false,
      loadingSession: false,
      loadingAppMap: false,
      toShowKeyFlows: 0,
      bugFinder: 0,
      loadingLatestSession: false,
      toShowLatestKeyFlow: 0,
      toShowAllKeyFlows: {},
      projectDetail: {},
      run_name: "",
      resultProject: [],
      resultMapp: [],
      toShowLatestProjects:[]
    };
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.loadingSession = true
        this.loadingAppMap = true
        this.loadingLatestSession = true
        await axios
          .get(`${process.env.VUE_APP_API_URL_PREFIX}/results/v1/user/stats`)
          .then((res) => {
            if (res && res.message === "Request failed with status code 402") {
              localStorage.removeItem("vue-authenticate.access_token");
              localStorage.removeItem("testgold.aioToken");
              localStorage.setItem(
                "login--message",
                "User trial period has ended. Please contact support@testgold.dev."
              );
              window.location.reload();
            } else {
              this.dashboardData = res.data.response;
              if (Object.keys(res.data.response).length === 0) {
                this.dashboardPieChart.title.text = "No Data Available";
              }
              let dpc = [];

              dpc.push(
                {
                  value: res.data.response.num_heals,
                  name: this.$t("dashboard.auto_healed") + ` ${res.data.response.num_healed}`,
                  itemStyle: {
                    color: "#4ac3db",
                  },
                },
                {
                  value: res.data.response.num_trained,
                  name: this.$t("dashboard.suggested") + ` ${res.data.response.num_trained}`,
                  itemStyle: {
                    color: "#FCA311",
                  },
                }
              );

              this.dashboardPieChart.series[0].data = [...dpc].reverse();
              this.dashboardPieChart.legend.data.push(
                this.$t("dashboard.auto_healed") + ` ${res.data.response.num_healed}`,
                this.$t("dashboard.suggested") + ` ${res.data.response.num_trained}`
              );
              let items = Object.values(res.data.response.test_runs);
              let tgtd = [];
              let dlcZeroData = [];
              let dlcOneData = [];
              let dlcxAxisData = [];
              items.map((v, i) => {
                tgtd.push({
                  name: v.name,
                  request_id: v.request_id,
                  step_count: v.step_count,
                });

                dlcZeroData.push(v.healed_count);
                dlcOneData.push(v.trained_count);
                let sec = moment(v.accepted_at);
                const time_display = sec.format("HH:mm");
                const day_display = sec.format("YYYY-MM-DD");
                dlcxAxisData.push(`${day_display} \n ${time_display}`);
              });

              this.testGenerationTableData = tgtd;

              var reversedlcZeroData = [...dlcZeroData].reverse();
              var reversedlcOneData = [...dlcOneData].reverse();
              var reversedlcxAxisData = [...dlcxAxisData].reverse();
              this.bugFinder = this.dashboardData.num_healed + this.dashboardData.num_suggested
              this.dashboardLineChart.series[0].data = reversedlcZeroData;
              this.dashboardLineChart.series[1].data = reversedlcOneData;
              this.dashboardLineChart.xAxis.data = reversedlcxAxisData;
              return res.data;
            }
          })
          .catch((e) => {
            this.$notify({
              type: "danger",
              message: `An error has occured ${e.response.message}`,
            });
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: `An error has occured ${e.response.message}`,
        });
      } finally {
        this.loading = false;
      }
    },
    getSlicName(name){
     let  tName = name.split(" ")
     if(tName.length > 1){
      return tName[0][0] +  tName[1][0]
     }else{
      return name[0] +  name[1]
     }

    },
    showComponent(id){
      if(id){
        this.$router.push({
          name: "List Sessions",
          params: {
            id: id,
          },
        });
      }
    },
    formatDate(a) {
      return moment(a).format("MMM DD YYYY, h:mm:ss a");
    },
    showDetailsSession(val) {
      const url = val.person.properties.$initial_current_url.replace(/https:\/\//g, "")
      this.run_name = url + " " + val.person.properties.$initial_browser + " " + val.person.properties.$geoip_country_name
      this.$router.push({
        name: "Details Session",
        params: {
          id: "158",
          session_id: val.id,
          run_name: this.run_name,
          start_time: val.start_time,
          recording_duration: val.recording_duration,
          name: "Afchess"
        }
      })
    },
    async getProject() {
      this.loadingProject = true;
      const url = `${process.env.VUE_APP_API_URL_PREFIX}/pageviews/user-projects`;
      try {
        axios.defaults.validateStatus = (status) => {
          // return status >= 200 && status < 401; // to catch 400 error
          return status === 400 || (status >= 200 && status < 500);
        };
        let isRepeat = ''
        const res = await axios.get(url);
        if ((res.data && res.data.status == "success") || res.data.status == 200) {
          this.resultProject = res.data.response;
          this.resultProject.map((item) => {
            if(localStorage.getItem("latest_projects")){
              let temp = JSON.parse(localStorage.getItem("latest_projects"))
              temp.map((itemTemp) => {
                if(itemTemp.name === item.name) {
                  if(isRepeat !== itemTemp.name) {
                    isRepeat = itemTemp.name
                    console.log(isRepeat)
                    this.toShowLatestProjects.push(item)
                  }
                }
              })
            }
          })
        } else {
          this.resultProject = [];
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          duration: 5000,
          message: "an error has occurred ",
        });
      }
    },
    secondsToRealTime(sec) {
      sec = Number(sec);
      let h = Math.floor(sec / 3600);
      let m = Math.floor((sec % 3600) / 60);
      let s = Math.floor((sec % 3600) % 60);
      let result = "";
      let numPreviousValues = 0;
      if (h > 0) {
        result += h + (h === 1 ? this.$t("dashboard.singular_hour") : this.$t("dashboard.plural_hour"));
        numPreviousValues++;
      }
      if (m > 0) {
        if (numPreviousValues > 0) {
          result += ", ";
        }
        result += m + (m === 1 ? this.$t("dashboard.singular_minute") : this.$t("dashboard.plural_minute"));
        numPreviousValues++;
      }

      if (s > 0 && (m === 0 || h === 0)) {
        if (numPreviousValues > 0) {
          result += ", ";
        }
        result += s + (s === 1 ? this.$t("dashboard.singular_second") : this.$t("dashboard.plural_second"));
        numPreviousValues++;
      }
      if(sec === 0)
        result = "0s"
      return result;
    },
    pad(num) {
      if (num) return ("" + num).slice(-2);
      return "";
    },
    hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      var dis_sec = Math.floor(secs % 60);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      let result = "";
      if (hours > 0) {
        result = result + `${this.pad(hours)}h `;
      }
      if (minutes > 0) {
        result = result + `${this.pad(minutes)}m `;
      }
      if (dis_sec > 0) {
        result = result + `${this.pad(dis_sec)}s `;
      }
      // return `${this.pad(hours)}h ${this.pad(minutes)}m ${this.pad(secs)}s`;
      return result;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    },

    getTimeSaved(a, b, c) {
      const totalHealSecs = a * 3600;
      const totalSuggestedSecs = b * 300;
      let total = totalHealSecs + totalSuggestedSecs;
      if (total) return this.hhmmss(total);
      else return "N/A";
    },
    secondsToRealTimeEvent(start, end) {
      return (Date.parse(end) - Date.parse(start)) / 1000
    },
    async getSessions() {
      this.loadingSession = true;
      this.resultProject.map(async (projectId) => {
        await axios
          .get(process.env.VUE_APP_API_URL_PREFIX + `/pageviews/events?after=2023-06-06T10:42:24.636000&before=2023-06-14T10:42:24.636000&project_id=${projectId.projectId}`)
          .then((response) => {
            if (response) {
              if (response.message === "Request failed with status code 500") {
                this.$notify({
                  type: "danger",
                  message: "an error has occured",
                })
                this.resultSession = []
                this.totalSession = 0
              } else if (response.data.status === "success") {
                this.totalSession += response.data.response.length
              } else {
                this.resultSession = [];
                this.totalSession = 0;
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      })

      this.loadingSession = false
    },
    async getAppMap() {
      this.loadingAppMap = true
      axios.defaults.validateStatus = (status) => {
        return status === 400 || (status >= 200 && status < 500)
      }
      const mainProject = await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/appmap?" + `project_id=${158}`)
      console.log(mainProject)
      if (mainProject.data.response !== null) {
        this.toShowAllKeyFlows = mainProject.data.flows
        this.toShowLatestKeyFlow = mainProject.data.flows.length - 1
      }
      this.resultProject.map(async (projectId) => {
        await axios.get(process.env.VUE_APP_API_URL_PREFIX + "/pageviews/appmap?" + `project_id=${projectId.projectId}`)
          .then((response) => {
            if (response) {
              if (response.data.status === "finished") {
                this.toShowKeyFlows += response.data.flows.length
              }
            }
          })
      })
      this.loadingAppMap = false
    },
    async getLatestSession() {
      this.loadingLatestSession = true
      await axios
        .get(process.env.VUE_APP_API_URL_PREFIX + `/pageviews/sessions?&project_id=158&limit=1`)
        .then((response) => {
          if (response) {
            if (response.message === "Request failed with status code 500") {
              this.$notify({
                type: "danger",
                message: "an error has occured",
              })
              this.resultSession = []
              this.totalSession = 0
            } else if (response.data.status === "success") {
              this.resultSession = response.data.response
              // this.totalSession = response.data.response.length
            } else {
              this.resultSession = [];
              this.totalSession = 0;
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.loadingLatestSession = false
    }
  },
  async created() {
    await this.getData()
    await this.getProject()
    await this.getLatestSession()
    await this.getSessions()
    await this.getAppMap()
  },
};
</script>
<style lang="scss">
$pColor: #525f7f;
$color: #5e72e4;

.container-content{
  width: 100%;
  overflow: scroll;
}
.eff--page{
  width: 100%;
}
.chart {
  width: 100%;
}

.temp {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.test-card-h {
  height: 500px;
}

.gen-heigh {
  height: 100%;
}

.test-heal {
  height: 100%;
}

.b {
  border: 1px solid red;
}

@media screen and (max-width: 1182px) {
  .r-3-cards {
    margin-top: 2rem;
  }
}

.only-body {
  padding: 0.5rem 0 !important;

  .card-body {
    .row {
      align-items: center;

      .card-details {
        margin-top: 3px;

        .card-subtitle {
          font-size: 1rem;
          font-weight: 700 !important;
        }
      }
    }
  }
}
.ctb--body {
  display: grid;
  justify-items: center;
  flex-wrap: wrap;

  .ctbb--row {
    width: 100% !important;
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    position: relative;

    &.ctbb--processing {
      .ctbb--inner {
        filter: blur(2px);
        display: none;
      }
    }

    .ctbb--inner {
      height: 100%;
      display: flex;
      flex-direction: column;

      .elt--banner {
        position: relative;
        .elt--banner_img {
          display: inline-block;
          width: 100%;
          text-align: center;
        }

        .elt--banner_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($color, 0.3);
        }

        .elt--banner_overlay_status {
          position: absolute;
          top: 10px;
          right: 10px;
          max-width: 200px;
          width: auto;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--banner_overlay_duration,
        .elt--banner_overlay_created {
          position: absolute;
          bottom: -10px;
          font-size: 0.7rem;
          font-weight: 700;
          color: #fff;
          background: rgba($color, 0.85);
          padding: 0.25rem;
          border-radius: 0.3rem;
        }

        .elt--banner_overlay_created {
          left: 10px;
        }
        .elt--banner_overlay_duration {
          right: 10px;
        }

        &.no--img {
          display: flex;
          align-items: center;
          height: 110%;
        }
      }

      .elt--content {
        padding: 0.6rem;
        margin-top: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .test-desc {
          font-size: 0.8rem;
          color: rgba($pColor, 0.75);
          font-style: italic;
        }

        .elt--value {
          font-weight: 700;
          font-size: 0.85rem;
          margin-right: 12px;
        }

        .el-divider {
          margin: 8px 0;
        }

        .el--footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border: solid red;
        }
      }
    }

    .ctbb--over {
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
      border-radius: 0.25rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 100%;
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
        border-radius: 0.25rem;
        margin: auto;
        border: solid 3px rgba(#108050, 0.7);
      }

      .s--icon {
        font-size: 3rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 600;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .s--txt {
        color: #108050;
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
}
.elt--banner_name{
  height: 150px;
  font-size: 7em;
  text-transform: uppercase;
  font-weight: bold;
  color: #5f72e4;
}
.tbl--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tblb--row {
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 0.25rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 30px -5px rgba(#111, 0.25);
      transition: all 0.25s ease-in;
    }

    &.tblb--processing {
      min-height: 350px;
      .tblb--inner {
        filter: blur(1px);
      }
    }

    .tblb--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .elt-row {
        padding: 1.4rem 0.8rem;
        font-size: 0.81rem;
        display: inline-block;
        text-align: left;
        &.flex--elt {
          display: flex;
        }

        .el-alert {
          display: inline;
          text-align: center;
          &.no--close {
            padding: 0.25rem 0.35rem;
            .el-alert__closebtn {
              display: none;
            }
          }
        }

        .elt--value {
          font-weight: 700;
        }

        .elt--txt {
          font-size: 0.7rem;
        }

        .elt--banner_img {
          border-radius: 0.35rem;
          overflow: hidden;
          width: 100%;

          img {
            display: inline-block;
            width: 100%;
          }
        }
      }
    }

    .tblb--over {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00ff6214;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 999;

      .tblbo--img {
        height: 100%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
      .s--icon {
        font-size: 2.5rem;
        font-weight: 700;
        color: #108050;

        i {
          font-weight: 900;
        }
      }

      .s--name {
        padding: 0.4rem 0.75rem;
        font-size: 0.85rem;
        font-weight: 600;
        color: #fff;
        background: rgba(#108050, 0.8);
        border: solid 0.1px #108050;
        border-radius: 0.2rem;
      }

      .tblo--step {
        .s--txt {
          color: #108050;
          font-size: 0.8rem;
          font-weight: 800;
        }
      }
    }
  }
}
</style>
